.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  // padding-top: 1.2em !important;
}

.ant-card-extra {
  float: right;
  margin-left: 0 !important;
  padding: 16px 0;

  font-weight: normal;
  font-size: 14px;
}


.anticon.ant-input-clear-icon {
  color: rgb(255, 0, 0) !important;
  z-index: 0 !important;
  // visibility: visible !important;
}

.ant-input[disabled] {
  color: black !important;
  background-color: white !important;
  cursor: not-allowed;
  padding-left: 0em !important;
  opacity: 1;
  border: none !important;
}

.shadow {
  position: absolute;
  width: 20em;
  height: 8em;
  box-shadow: inset 0px 0px 40px 40px #104155;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  width: 30em;
  margin-top: -2em;
  /* object-fit: cover; */
  height: 19em;
  /* border-radius: 0em; */
  margin: auto;
  /* margin-top: -8em; */
  z-index: 9999999;
}

form .ant-mentions,
form textarea.ant-input {
  margin-bottom: 0px !important;
}

.ant-tabs-top>.ant-tabs-nav::before,
.ant-tabs-bottom>.ant-tabs-nav::before,
.ant-tabs-top>div>.ant-tabs-nav::before,
.ant-tabs-bottom>div>.ant-tabs-nav::before {
  position: absolute;
  right: 0;
  left: 0;
  border-bottom: 1px solid #f0f0f0 !important;
  content: '';
}

.inner-container {
  // display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.error {
  font-size: 2rem;
  color: red;
  text-transform: capitalize;
}

.status {
  font-size: 2rem;
  color: black;
  text-transform: capitalize;
}

.timer {
  position: fixed;
  z-index: 2;
  right: 1em;
  top: 7em;
  margin-bottom: 0em !important;
  font-weight: 500;
  letter-spacing: 0.2rem;
  color: black;
}

.blink-animation {
  color: red !important;
  animation-name: blink;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

audio {
  margin: 12px 0;
}

.buttons {
  // margin-top: 2.4rem;
  display: flex;
  align-items: center;
}

button {
  border: none;
  cursor: pointer;
  border-radius: 50%;
  color: black;
}
.ant-progress-circle .ant-progress-inner {
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;

}
.btn-record {
  position: fixed;
  top: 5em;
  right: 0.5em;
  font-size: 14px;
  width: 45px;
  height: 45px;
  margin-left: 0.7em;
  font-size: 1.5rem;
  color: white;

}

.btn-stop {
  position: fixed;
  top: 5em;
  right: 0.5em;
  font-size: 14px;
  width: 45px;
  height: 45px;
  margin-left: 0.7em;
  font-size: 1.5rem;
  color: white;
}

.btn-play {
  position: fixed;
  top: 7em;
  right: 0.5em;
  font-size: 14px;
  width: 45px;
  height: 45px;
  margin-left: 0.7em;
  font-size: 1.5rem;
  color: white;
  background-color: #bcbcbc;
}

@keyframes blink {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}